import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { ProvideAuth, useAuth } from '../hooks/useAuth';
import { toast, ToastContainer } from 'react-toastify';
import { Fade } from '../components/common/Toast';
import Loader from '../components/common/Loader';

const DashboardLayout = lazy(() => import('./DashboardLayout'));
const AuthBasicLayout = lazy(() => import('./AuthBasicLayout'));
const ErrorLayout = lazy(() => import('./ErrorLayout'));


const Layout = () => {
  return (
    <Suspense fallback={<span />}>
      <ProvideAuth>
        <Router fallback={<span />}>
          <Switch>
            <Route path="/auth" component={AuthBasicLayout} />
            <Route path="/errors" component={ErrorLayout} />

            <PrivateRoute path="/" component={DashboardLayout} />
          </Switch>
          <ToastContainer limit={2} autoClose={3000} transition={Fade} hideProgressBar closeButton={false} closeOnClick={true} pauseOnFocusLoss={false} draggable={false} position={toast.POSITION.BOTTOM_CENTER} />
        </Router>
      </ProvideAuth>
    </Suspense>
  );
};

export default Layout;

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { loggedIn, user } = useAuth()

  if (loggedIn() && !user) {
    return <Loader type="border" />
  }

  return (<Route
    {...rest}
    render={props =>
      loggedIn() ? (
        <Component {...props} />
      ) : <Redirect to={{ pathname: "/auth/login", search: `?next=${props.location.pathname}` }} />
    }
  />)
};